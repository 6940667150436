import React from "react";
import "../styles/TermsModal.scss";

const TermsModal = ({ setTermsCondition }) => {
  const data = [
    { id: 1, title: "Name of the Offer", description: `‘’Sona Jeeto Offer” Navratri & Diwali Consumer Reward Offer  (‘Offer”)` },
    {
      id: 2,
      title: "Provided by",
      description:
        "Samsung India Electronics Pvt. Ltd. (“Samsung”)",
    },
    {
      id: 3,
      title: "Offer Period",
      description:
        "Offer shall be valid from 16th October 2024 until 31st October 2024 (“Offer Period”). Samsung reserves the right to extend or annul the offer period at its discretion.",
    },
    {
      id: 4,
      title: "Place & Outlet Coverage",
      description: [
        "Offer shall be valid on purchase of products from Outlet (as defined below) within the State of Gujarat (including Daman, Diu, and Dadra and Nagar Haveli region).",
        `Available on purchases from Samsung exclusive stores, multi-brand outlets and general trade outlets (hereinafter referred as "Outlet(s)").`,
        "Offer shall not be applicable on purchases made from e-commerce websites or any other Outlet other than the one's mentioned above."
      ]
    },
    {
      id: 5,
      title: "Product Coverage",
      description:
        [
          'Any Smartphone, Tablet & Wearable of Samsung brand on Market Operating Price (MOP) (“Product”).',
          "Offer shall not be valid on refurbished products"
        ],
    },
    {
      id: 6,
      title: "Eligibility for Participation",
      description:
        "Offer valid for Indian Citizens who have attained the age of 18 years or above, except employees & family members of Samsung & its affiliates/associate/subsidiary companies & their dealers/distributors/retailers, suppliers, advertising agencies & even management companies.",
    },
    {
      id: 7,
      title: "Participation Criteria",
      description:
        "Participant needs to purchase and activate the new Product from the Outlet during the Offer Period only.",
    },
    {
      id: 8,
      title: "Prize Details/Prize Matrix",
      description:  [
        "Consumers will have the chance to win gold vouchers based on their purchase of Products at Outlet during the Offer Period only.",
        "Eligibility for Rewards",
        "Every purchase of the Product qualifies participants for a chance to win rewards as outlined below.",
        "Prize Categories :",
        [
          "Daily Prizes: Gold vouchers valued at ₹5000 each (10 winners per day).",
          "Weekly Prizes: Gold vouchers valued at ₹25000 each (10 winners per week).",
          "Auspicious Day Prizes: Gold vouchers valued at ₹100000 each, awarded on the following auspicious days: Dhanteras, and Diwali (one winner per day).",
          "Monthly Bumper Prize: Gold voucher valued at ₹300000 (1 winner per offer Period)."
        ],
        "Voucher Validity and Redemption :",
        [
          "All Gold vouchers shall be valid for 30 days from the date of issuance.",
          `These Gold vouchers must be redeemed between Monday and Sunday, with no blackout periods, within the 30-days validity period from the date of issuance ("Redemption Period").`,
          "Redemption of gold vouchers is to be conducted either through the Kalyan Jewelers retail stores or official website (www.kalyanjewellers.net) located in Gujarat, including Daman, Diu, and Dadra and Nagar Haveli. All Gold vouchers being provided on 'As Is' basis subject to terms and conditions of the respective party providing these vouchers. Samsung disclaims any claim or liability in this regard."
        ],
        "Lucky Draw Details:",
        [
          "Daily Draw: A daily draw will be conducted, selecting 10 winners each day: 2 from Ahmedabad Zone, 2 from Baroda Zone, 2 from Surat Zone, 2 from Rajkot Zone, and 2 from North Gujarat Zone. Each winner will receive a one unit of gold voucher worth ₹5000. A total of 160 daily prizes will be awarded throughout the Offer Period.",
          "Daily Winner will be announced on the next day (following day) from the day of purchase & activation.",
          "Weekly Draw: A weekly draw will select 10 winners, with 2 winners from each zone (total five zones: Ahmedabad, Baroda, Rajkot, Surat & North Gujarat), each winner receiving a one unit of gold voucher valued at ₹25000.",
          "Weekly Winner will be announced On following/next day of every week closure (ie. 22nd and 29th of Oct respectively)",
          "Week definition is mentioned below:",
          "Week 1st -15th to 21st of October, 2024; and",
          "Week 2nd - 22nd to 28th of October, 2024.",
          "Auspicious Day Draw: On each auspicious day (i.e. Dhanteras, and Diwali dated 29th October, 31st October of 2024 respectively), winner (one winner across region) will receive a one unit of gold voucher valued at ₹100000.",
          "Auspicious day Winner will be announced On following/next day of each auspicious day",
          "Monthly Bumper Draw: At the end of the Offer Period, 6th November, 2024 (Labh panchami), receive a gold voucher valued at ₹300000."
        ],
        "Draw Eligibility and Restrictions :",
        [
          "Winners of the Daily, Weekly, and Auspicious Day draws are ineligible to participate in the Monthly Bumper Draw.",
          "Redemption of Gold vouchers worth ₹100000 and ₹300000 must be conducted in person at a Kalyan Jewellers nearby gold store of the voucher given, online redemption is not permitted for these amounts."
        ],
        "Auspicious Day Draw",
        [
          "One draw for Auspicious Day will be conducted per region (including Gujarat, Daman, Diu, and Dadra and Nagar Haveli). All valid entries submitted for auspicious days (excluding winners of daily draws), will be included in the Auspicious Day draw. One winner per region will be awarded a one unit of gold voucher valued at ₹100000. Winners are required to visit a Kalyan Jewellers store for voucher redemption; online redemption is not available."
        ],
        "Bumper Draw",
        [
          `At the conclusion of the Offer Period, one bumper draw will be conducted for the entire region. All valid entries, excluding winners of daily, weekly, and auspicious day draws, will be eligible for this draw. One winner will receive a one unit of gold voucher valued at ₹300000. Redemption of this voucher must be conducted in person at a Kalyan Jewellers showroom; online redemption is not permitted`
        ]
      ]
    },
    {
      id: 9,
      title: "Selection of winner",
      description:   [
        "",
        [
          "Winners will be selected through random system generated lucky draws from the eligible IMEI (1st IMEI number) /Serial number of their purchased product with Mobile numbers & details received by QR code scanning during the Offer Period",
          "In this regard, Participants / Customers to fill IMEI (1st IMEI) / Serial number of their purchased Product on given QR (microsite) at their sole discretion for participation in Offer Poster / Banner / Standee / POSM material. Participation in the Offer by Customers/ Participants shall be on voluntarily consent basis only. Samsung disclaims any and all claims in respect of the same.",
          "Draw for the daily winners shall be conducted on daily basis by the authorized third party agency whose decision shall be final and no claims shall be entertained in this regard",
          "Bumper draw will happen after end of the Offer Period i.e. 31st October 2024  & result will be announced on/post that (Labh Pancham – 6th November, 2024 day respectively.",
          `The results will be uploaded on https://www.samsung.com/in/offer/regional-promo-2024/  within 15 days from the end of the Offer Period for all lucky draws.`
        ],
      ]
    },
    {
      id: 10,
      title: "Documents to be submitted by the Participant for availing the Prize",
      description:   [
        "Following documents need to be submitted to third party agency within Fifteen (15) days either from date of intimation of all prizes of all draws as mentioned above to the winner through telephone/SMS/email or from the declaration of the winners on https://www.samsung.com/in/offer/regional-promo-2024/ whichever is earlier.",
        [
          "Name, Address, Contact number, Alternate Mobile number, email id",
          "Copy of Invoice for the purchase ",
          "Copy of PAN card",
          "Valid POA & POI – Voter Card, Passport, Driving License, electricity bill (not older than last 6 months)",
          "Any other document as informed or required by the Company",
          "DD for TDS amount (if applicable) will be collected by Samsung India Electronics Pvt. Ltd at Ahmedabad (Applicable for Gift Voucher)."
        ],
        "For collecting the Prizes winners needs to carry the originals of the above-mentioned documents. "
      ]
    },
    {
      id: 11,
      title: "Verification of the documents & contacting the winners",
      description:   [
        "Documents submitted by the winners will be subjected to verification and validation by Samsung directly or through its authorized third party agency which may be stationed in India.",
        [
          "Post verification & validation the eligible winners will be informed through telephone/SMS/email as submitted by them at the time of participation by Samsung or its authorized third party agency. ",
          "The date, time & venue for collecting the Prizes shall be informed to the winners and the same will be subject to submission of valid documents and applicable taxes as detailed in the clauses below.",
          "In case any document is prima facie found to be false & fabricated, the entry of such Participant/winner shall be disqualified and no claims shall be entertained in this regard.",
        ]
      ]
    },
    {
      id: 12,
      title: "Delivery of Bumper Draw Prize & Others",
      description:   [
        "Post verification, All Prizes (i.e. Gift Voucher Codes) will be sent on MyGalaxy app/registered mobile numbers/email to the winners only at the date, time & venue communicated to the winners. All prizes will be handover through/by MyGalaxy app/ third party agency after receiving of above-mentioned documents in XI.",
        [
         
        ]
      ]
    },
    {
      id: 13,
      title: "Applicable Taxes clause",
      description:   [
        "All applicable taxes to be borne by the Winner and Samsung or its authorized third party shall not be responsible / liable for the same. DD has to be issued by the Winner in favor of Samsung India Electronics Pvt. Ltd at Ahmedabad.",
        [
         
        ]
      ]
    },
    {
      id: 14,
      title: "Reminder (including forfeiture clause)",
      description:   [
        "If the eligible winner fails to submit the documents within fifteen (15) days either from the date of intimation or from the date of publication of the results on the URL link, Samsung store shall send a reminder notice through call/email/SMS to the respective winner. If the required documents and the applicable taxes are still not received within fifteen (15) days from the said reminder, the Prize of the winner shall stand forfeited and no claims shall be entertained in this regard against Samsung or its authorized third party agency.",
        [
         
        ]
      ]
    },
    {
      id: 15,
      title: "Conditions related to Prize",
      description:   [
        "All Prizes shall be on As Is basis & as per stocks availability. Samsung shall not be responsible for non-availability of Prize due to various reasons like climatic conditions, labour unrest, insolvency, business exigencies, Government decisions, operational & technical issues or any other force majeure conditions or any other reason beyond its control etc.",
        "Prize cannot be exchanged with any other product or item, is no-transferable & cannot be en-cashed under any circumstances.",
        [
         
        ]
      ]
    },
    {
      id: 16,
      title: "Publicity",
      description:   [
        "Participant unconditionally consent to accessing of information and/or images of Participant by Samsung or its authorized agency for media coverage, advertisement or publicity for present & future communications without any further consideration to the Participant including promotion of its products anywhere in the World.",
        [
         
        ]
      ]
    },
    {
      id: 17,
      title: "Intellectual Property Rights",
      description:   [
        "All rights, title and interest including but not limited to the Intellectual Property Rights in the promotional material(s) and any/all registrations received shall vest solely and exclusively with Samsung at all times and Samsung shall be entitled to use the database of the entries received or any information in any media for future promotional, marketing and publicity purpose without any further reference or payment or compensation to Participant. Participant shall be solely responsible for any consequences which may arise due to any kind of infringement of copyrights or any kind of intellectual property rights belonging to any other person/ entity etc. and also undertake to indemnify Samsung and its officers, directors, employees etc on the happening of such an event (including without limitation to cost of attorney, legal charges etc.) on full indemnity basis.",
        [
         
        ]
      ]
    },
    {
      id: 18,
      title: "General Conditions",
      description:   [
        "",
        [
         "Participant represents & warrants that he/she is legally competent to enter into binding contracts under applicable laws and any/all information submitted by him/her is true, correct, accurate & complete.",
         "By Participating in the Offer, it means that the Participant has given its consent to Samsung and/or its third party agency (located in India) to contact them through call, SMS or email with respect to this Offer and shall not make any claim or raise any complaint against them.",
         "Participant agrees that Samsung shall not be liable for any claims, costs, injuries, losses or damages of any kind arising out of or in connection with the Offer or with the acceptance, possession or use of any Prize.",
         "Participant agrees to indemnify Samsung, its associated companies, event management company & their respective directors, officers, employees, contractors and agents, indemnified against any and all losses, claims (including but not limited to third party claims), injuries, costs, fees, fines, penalties, taxes, charges and any other liability arising out of any act of omission, commission, fraud, negligence or misconduct by the Participant.",
         "Samsung reserves the right to change/alter & modify the terms & conditions of this Offer at any given point at its sole discretion without giving any reason whatsoever.",
         "Samsung reserves the right to substitute the Prize(s) with Prize(s) of equivalent value under this Offer at its sole discretion without giving any reasons. The choice as to the color, make & model of the Prize(s) shall be completely at Samsung’s discretion.",
         "Samsung shall not be liable for any technical, physical delay in transmission or submission of the entries by the Participant or any disruptions, losses, damages, computer related malfunctions/ failures which affect the participation of the Participant or any force majeure conditions or damages caused by Act of God, Governmental actions.",
         "Samsung shall also not be responsible for non-availability of Stock(s) or Prize(s) due to various reasons like climatic conditions, labour unrest, insolvency, business exigencies or operational & technical issues.",
         "Samsung shall have the right to disqualify / cancel the entries of any Participant with incomplete information or illegal contents (statement of racist, sexist or defamatory) or on any other ground without giving any reasons.",
         "Failure by Samsung to enforce any of its right at any stage does not constitute a waiver of those rights.",
         "In no event shall Samsung be liable for losses or any incidental or consequential damages arising out of or in connection with the Offer, its services these Terms (however arising, including negligence). Samsung shall not be responsible for any product or service liability of any kind.",
         "In the event of any conflict or inconsistency regarding any instructions, rules and conditions on any advertising or promotional material relating to the Offer, these Terms shall prevail over all such other instructions, rules and conditions."
        ]
      ]
    },
    {
      id: 19,
      title: "Jurisdiction",
      description:   [
        "Decision of Samsung in relation to the Offer and matter(s) incidental thereto shall be final and binding on the Participant. All disputes shall be governed by the laws of India. These Terms shall be governed by exclusive laws of India and the courts located at New Delhi, India shall have the exclusive jurisdiction in respect of all the subject matter with relation to the Offer.",
        [
         
        ]
      ]
    },
  ];
  const renderDescriptionText = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => 
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };
  const renderDescription = (description) => {
    if (Array.isArray(description)) {
      return (
        <div>
          {description.map((point, i) => (
            Array.isArray(point) ? (
              <ul key={i}>
                {point.map((subPoint, j) => (
                  <li key={j}>{renderDescriptionText(subPoint)}</li>
                ))}
              </ul>
            ) : (
              <p key={i}>{renderDescriptionText(point)}</p>
            )
          ))}
        </div>
      );
    } else {
      return renderDescriptionText(description);
    }
  };
  return (
    <div className="terms-conditions">
      <h2 className="termsConditionstext">Terms & Conditions</h2>
      <div className="table-container">
      <table className="terms-table">
          <thead>
            <tr>
              <th >No.</th>
              <th >Title</th>
              <th >Description</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td style={{ fontWeight: "700" }}>{item.title}</td>
                <td>{renderDescription(item.description)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="close-btn" onClick={() => setTermsCondition(false)}>
        Close
      </button>
    </div>
  );
};

export default TermsModal;
