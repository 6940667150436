import React from "react";
import "../styles/Button.scss";

const Button = ({ label,type }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button type={type} className="submit-btn">{label}</button>
    </div>
  );
};

export default Button;
