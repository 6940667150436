import React from "react";
import "../styles/Header.scss";

const Header = () => {
  return (
    <div className="header">
      {/* <h1>Sona jeeto</h1> */}
    </div>
  );
};

export default Header;
