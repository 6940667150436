import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Form from "./components/Form.jsx";
import TermsModal from "./components/TermsModal";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="app">
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <Form openModal={openModal} />
      {showModal && <TermsModal closeModal={closeModal} />}
    </div>
  );
};

export default App;
