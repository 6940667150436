import React from "react";
import "../styles/FormInput.scss";

const FormInput = ({ label, type, name, value, onChange, errors }) => {
  return (
    <div className="form-input">
      <label>{label.endsWith('*') 
          ? <>{label.slice(0, -1)}<span style={{color:"red"}} className="required-asterisk">*</span></>
          : label
        }</label>
      <input type={type}  
        name={name}
        value={value}
        onChange={onChange} />
      {errors && <p style={{ color: "red", margin: "0", fontSize: "10px" }}>{errors}</p>}
    </div>
  );
};

export default FormInput;
